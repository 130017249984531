import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "全社で本気になってリーンに ISMS の仕組みをつくった話",
  "date": "2019-02-01T08:24:57.000Z",
  "slug": "entry/2019/02/01/172457",
  "tags": ["medley"],
  "hero": "./2019_02_01.png",
  "heroAlt": "ISMS"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、コーポレートエンジニアの兼松です。`}</p>
    <p>{`今回は、メドレーがリーンな仕組みで ISMS 認証を取得したので、その過程について、弊社ならではの工夫したポイントを交えてご紹介します。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`以下のニュースリリースのとおり、情報セキュリティ管理の仕組みである ISMS を構築し、オンライン診療システム「CLINICS」、クラウド型電子カルテ「CLINICS カルテ」の事業において「ISMS 認証」と「ISMS クラウドセキュリティ認証」という 2 つの第三者認証を同時取得しました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="メドレーが国際規格に基づく ISMS クラウドセキュリティ認証を取得 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frelease%2Fisms.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/release/isms.html">www.medley.jp</a></cite>
    <p>{`特に「ISMS クラウドセキュリティ認証」は、国内でも取得している企業はまだ少なく、現時点で私の知る限り、オンライン診療システムとしては弊社の CLINICS（SaaS）が日本初だと思います。`}</p>
    <h1>{`ISMS とは`}</h1>
    <p>{`ISMS とは、Information Security Management System の略で、組織における情報セキュリティを管理するための枠組みのことです。具体的には、国際規格である`}<a parentName="p" {...{
        "href": "https://www.iso.org/isoiec-27001-information-security.html"
      }}>{`ISO/IEC 27001`}</a>{`に定められた様々な要求事項を満たし、組織内に情報セキュリティ水準向上のための体制を整備してプロセスを実行し続ける仕組みのことです。`}</p>
    <h1>{`「ISMS 認証」と「ISMS クラウドセキュリティ認証」とは`}</h1>
    <p>{`「ISMS 認証」とは、ISMS が適切に構築されていることを第三者機関が認証する仕組みです。また、「ISMS クラウドセキュリティ認証」は、ISMS 認証の取得を前提として ISO/IEC27017 に定められたクラウドサービス固有の情報セキュリティ管理要件を満たしている組織を第三者機関が認証する仕組みです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190201/20190201170325.png",
        "alt": "f:id:medley_inc:20190201170325p:plain",
        "title": "f:id:medley_inc:20190201170325p:plain"
      }}></img>{`(「ISMS クラウドセキュリティ認証」は「ISMS 認証」の取得を前提とした、クラウドセキュリティ分野に特化した追加認証という位置付け)`}</p>
    <p>{`クラウドサービスを利用する組織（CSC）とクラウドサービスを提供する組織（CSP）の両方の組織を対象としており、メドレーは SaaS 提供企業として CSC と CSP の両方に該当します。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190201/20190201170346.png",
        "alt": "f:id:medley_inc:20190201170346p:plain",
        "title": "f:id:medley_inc:20190201170346p:plain"
      }}></img></p>
    <p>{`(`}<a parentName="p" {...{
        "href": "https://www.bsigroup.com/ja-JP/ISO27017/"
      }}>{`https://www.bsigroup.com/ja-JP/ISO27017/`}</a>{` より引用)`}</p>
    <h1>{`なぜ ISMS 認証を取得することにしたのか`}</h1>
    <p>{`メドレーでは、これまでも個人情報保護認証の「`}<a parentName="p" {...{
        "href": "https://www.truste.or.jp/more/"
      }}>{`TRUSTe`}</a>{`」マークの取得や、CLINICS 事業においては医療情報を取り扱う際の安全管理に関するガイドラインである「3 省 3 ガイドライン」への対応など、情報セキュリティ体制の強化に努めてきました。`}</p>
    <p>{`しかし、機密性の高い情報を取り扱うサービスをクラウドで提供する企業として、顧客からのより高度なセキュリティ、コンプライアンス要求にお応えできる体制を作っていく、という経営陣の強い意思決定がキッカケとなり、上記の取り組みに加えて ISMS 認証を活用しての組織的な仕組みづくりをすることに決まりました。`}</p>
    <p>{`そしてさらに CLINICS 事業では、医療情報という機密性の非常に高い情報を取り扱うサービスをクラウドで提供するため、導入医療機関様や患者の皆様により安心して利用してもらえるよう、今回取得した 2 つの第三者認証を取得することに決定しました。`}</p>
    <h1>{`脱「ISMS あるある」`}</h1>
    <p>{`ISMS についてご存知の方は、ISMS 認証取得について少々ネガティブなイメージを持たれている方も少なくないかもしれません。`}</p>
    <p>{`例えば、「分厚い規程類がたくさん出来上がる」、「ガチガチのルール作りを強いられる」、「やたらと手間のかかる台帳を使わないといけない」といったような、「はぁ、昔はラクで良かった…。」というイメージです。`}</p>
    <p>{`私自身も、過去に ISMS 活動の一環として、勤務先が決めた面倒なルール遵守をたくさん強いられてきた記憶がありましたし、後述しますが今回一緒に取り組んだメンバーの多くも、同じような経験をしてきていました。`}</p>
    <p>{`そこで、堅牢なセキュリティにしつつも、このような面倒な作業をできるだけせずに、自分自身が働きたい環境となるよう、脱「ISMS あるある」のために意識したポイントや工夫の一部を 3 点ほどご紹介したいと思います。`}</p>
    <p>{`ポイント１：ゼロベースで考える`}</p>
    <p>{`今回の ISMS 取得プロジェクトにおいて初志貫徹したコンセプトがあります。それは、「ムダを削ぎ落としたリーンな仕組みを作る」ということです。`}</p>
    <p>{`多くの組織は、認証取得支援コンサル会社などから入手した雛型をバコッとはめて、結果として大量の社内文書ができあがったりしてしまいがちですが、今回は本来要求されている ISO 規格の要求事項を本質的に実現するにはどうすればよいかという事に注力しました。`}</p>
    <p>{`例えば、プロジェクトミーティングで、情報セキュリティ委員会（ISMS 取得企業では設置するのが一般的）の設置について、私が「そもそも、これって必要ありますか？」と発言した際には、他社で ISMS 取得経験があるメンバーに唖然とされました。あとで「あの時は、この調子で進んで本当に認証取得できるのか不安でしたよ（笑）」と言われたほどです。`}</p>
    <p>{`このように、当初からこのポイントをいちばん大切にしており、形式だけの仕組みができる位なら、むしろやらない方が良いというくらいに考えて取り組んでいました。`}</p>
    <p>{`ポイント２：各部門の責任者と共創する`}</p>
    <p>{`ムダがなくサスティナブルな仕組みづくりのためには、各部門の視点からしっかりと考えていく必要があります。そこで、責任者クラスでの組織横断の ISMS 取得プロジェクトを発足させました。`}</p>
    <p>{`例えば、管理部門サイドの視点だけで仕組みを作ってしまうと、どうしても事業の実態に沿わないムダの多い仕組みとなりがちです。また、エンジニアサイドの視点だけで作ってしまうと、ムダの少ないオペレーションとなる一方で既存規程との不整合などがおこってしまうことも考えられます。`}</p>
    <p>{`そこで、メドレーでは、コーポレート IT 責任者が全体統括を担当しつつも、開発業務とプロダクトに精通`}<strong parentName="p">{`する開発部長と、`}</strong>{`事業プロセスに精通`}<strong parentName="p">{`する事業責任者およびマネージャを中心メンバーとしてプロジェクトを作りました。また、`}</strong>{`他社での取得経験者`}<strong parentName="p">{`や社内`}</strong>{`弁護士ともコラボレーションして規程群を作成しました。`}</p>
    <p>{`この体制により、最も難しいタスクの一つであるリスク対策の決定や、各部門での実オペレーションへの組込みにおいて、実用的な管理策を「即断・即決・即実行」できました。`}</p>
    <p>{`この取り組みの成果として例えば、ISMS 管理策の事例として重複管理や更新漏れなどがよく発生しがちな「管理台帳」を、極力作らずに運用できるようになりました。また、情報の重要度を識別するための ISMS 要件である「情報資産に対するラベリング」も、リスク発生のケースを具体的に想定することで特定のケースのみラベルを付けることとしました。`}</p>
    <p>{`各部門の責任者と共にこのような細かな工夫の積み重ねることによって、全従業員にとって手間の少ないシンプルな設計にすることができました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190201/20190201170507.jpg",
        "alt": "f:id:medley_inc:20190201170507j:plain",
        "title": "f:id:medley_inc:20190201170507j:plain"
      }}></img></p>
    <p>{`（開発部長、事業責任者、他社における ISMS 認証取得経験者と共に記念撮影。左から 2 人目が筆者。）`}</p>
    <p>{`ポイント３：情報を集約する`}</p>
    <p>{`最後に特徴的なポイントとして、セキュアなクラウド環境に情報を集約したことです。元々メドレーの社内にはサーバを置いていません。もちろん管理するデータセンターなどもなく、全員クラウドベースで業務しています。`}</p>
    <p>{`そして、そのクラウド上の厳選したセキュアなツールに、機密度や用途に応じて情報を集約して保管することで、重複や探す手間を極力少なくしています。例えば、規程群・業務フロー/マニュアル・営業資料・企画書などのストックとフロー情報は基本的に全て`}<a parentName="p" {...{
        "href": "https://ja.atlassian.com/software/confluence"
      }}>{`Confluence`}</a>{`(社内 Wiki)に構造化して保管されています。さらにオープンを原則としているため社内のコラボレーションもとてもスムーズになっています。`}</p>
    <p>{`このように、どこに何があるかが誰にとっても明確になっているので、リスクも把握しやすく対策も立てやすくなります。結果としてセキュリティレベルも向上します。`}</p>
    <p>{`また、認証審査の際には、審査員から求められた情報を瞬時に提示することができました。結果、あまりにも審査の進捗が速く進んだため、審査員の方々にとても驚かれました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190201/20190201170529.png",
        "alt": "f:id:medley_inc:20190201170529p:plain",
        "title": "f:id:medley_inc:20190201170529p:plain"
      }}></img></p>
    <p>{`（情報を集約している弊社の Confluence の一例）`}</p>
    <h1>{`審査機関の反応`}</h1>
    <p>{`認証審査の第三者機関からは、以下のような点でメドレーの取り組みに対して非常に高い評価を受けることができました。`}</p>
    <p>{`-オープンな社内コミュニケーション環境による情報共有や意思疎通 -開発部門と事業部門との協業関係 -役割責任の明確化と専門家(医師、弁護士)のアサイン -組織における機微情報であるカスタマーデータに対する高いセキュリティ管理運用`}</p>
    <p>{`など`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190201/20190201170557.png",
        "alt": "f:id:medley_inc:20190201170557p:plain",
        "title": "f:id:medley_inc:20190201170557p:plain"
      }}></img></p>
    <p>{`（取得した「ISMS クラウドセキュリティ認証」マーク）`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190201/20190201170613.png",
        "alt": "f:id:medley_inc:20190201170613p:plain",
        "title": "f:id:medley_inc:20190201170613p:plain"
      }}></img></p>
    <p>{`（取得した「ISMS 認証」マーク）`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`今回の活動を通して改めて感じたことがあります。それは、こういった活動をやり遂げるために最も大切なのは、社長や CTO を始めとする「経営陣の情報セキュリティに対する意識の高さとコミット」だということです。`}</p>
    <p>{`今回、メドレーでは経営陣が社内メッセージを発信し続けたからこそ、部門横断でコラボレーションし、このような工夫を生むことができたのだと思います。`}</p>
    <p>{`これからも弊社では、ISMS をさらに磨きあげ、事業運営の効率化にまで寄与する ISMS として運用することで、良質なサービスの提供を通じた"納得できる医療"の実現を目指していきたいと思います。
最後まで拙文を読んでいただき、ありがとうございました。`}</p>
    <p>{`メドレーでは、提供サービスの拡大を受けて、その成長を支えるクリエイター（エンジニア・デザイナー）を募集しています。`}</p>
    <p>{`また、組織も急成長しているため、組織の抱える課題や社内 IT 環境について、様々な効率化や改革を進めるコーポレートエンジニアを募集しています。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CREATOR'S STORY | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fteam%2Fcreator-story.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/team/creator-story.html">www.medley.jp</a></cite>
    <p>{`ISMS の情報交換とかでも結構です。`}</p>
    <p>{`少しでも興味を持っていただいた方、ぜひお気軽にご連絡ください！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      